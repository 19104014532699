import { Component } from '@angular/core';

@Component({
  selector: 'app-subscribe-save',
  templateUrl: './subscribe-save.component.html',
  styleUrls: ['./subscribe-save.component.css']
})
export class SubscribeSaveComponent {

}
