import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";


import { SidebarComponent } from "./sidebar/sidebar.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { FooterComponent } from "./footer/footer.component";

import { RouterModule } from "@angular/router";
import { ValueTrayPopupComponent } from './value-tray-popup/value-tray-popup.component';
import { OneTimePurchaseComponent } from './one-time-purchase/one-time-purchase.component';
import { SubscribeSaveComponent } from './subscribe-save/subscribe-save.component';


const sharedComponents = [
  SidebarComponent,
  NavbarComponent,
  FooterComponent
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule
  ],
  declarations: [
    sharedComponents,
    ValueTrayPopupComponent,
    OneTimePurchaseComponent,
    SubscribeSaveComponent
  ],
  exports: [
    sharedComponents
  ],
  providers: [
  ]
})
export class ComponentsModule {}
