import { Component } from '@angular/core';

@Component({
  selector: 'app-value-tray-popup',
  templateUrl: './value-tray-popup.component.html',
  styleUrls: ['./value-tray-popup.component.css']
})
export class ValueTrayPopupComponent {

}
