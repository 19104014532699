import { Component } from '@angular/core';

@Component({
  selector: 'app-one-time-purchase',
  templateUrl: './one-time-purchase.component.html',
  styleUrls: ['./one-time-purchase.component.css']
})
export class OneTimePurchaseComponent {

}
